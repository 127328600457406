<template>
  <div class="animated fadeIn">
    <b-card
      header="New Machine"
      class="card-columns cols-2"
    >
      <b-form
        @submit.prevent="create"
        @reset="reset"
      >
        <div class="example-avatar">
          <div
            v-show="$refs.upload && $refs.upload.dropActive"
            class="drop-active"
          >
          </div>
          <div
            class="avatar-upload"
            v-show="!edit"
          >
            <div class="text-center p-2">
              <label for="avatar">
                <img
                  :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"
                  class="rounded"
                />
              </label>
            </div>
            <div class="text-center p-2">
              <file-upload
                extensions="gif,jpg,jpeg,png,webp"
                accept="image/png,image/gif,image/jpeg,image/webp"
                name="avatar"
                class="btn btn-primary"
                :custom-action="customUpload"
                :drop="!edit"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload"
              >
                Upload Image
              </file-upload>
            </div>
          </div>

          <div
            class="avatar-edit"
            v-show="files.length && edit"
          >
            <div
              class="avatar-edit-image"
              v-if="files.length"
            >
              <img
                ref="editImage"
                :src="files[0].url"
              />
            </div>
            <div class="text-center p-4">
              <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="$refs.upload.clear"
              >Cancel</button>
              <button
                type="submit"
                class="btn btn-primary"
                @click.prevent="editSave"
              >Save</button>
            </div>
          </div>

        </div>
        <table class="table">
          <tr>
            <td>{{ $t('message.type') }}</td>
            <td>
              <b-form-select
                v-model="type"
                :options="machineType"
                text-field="name"
                value-field="id"
                @input="getMachineModel()"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.machineModel') }}</td>
            <td>
              <b-form-select
                v-model="machineData.modelId"
                :options="machineModel"
                text-field="name"
                value-field="id"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.user') }}</td>
            <td>
              <b-form-select
                v-model="machineData.userId"
                :options="userList"
                text-field="userName"
                value-field="userId"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.simProvider') }}</td>
            <td>
              <b-form-select
                v-model="provider"
                :options="ProviderList"
                text-field="name"
                value-field="id"
                @input="getSim()"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.simSerial') }}</td>
            <td>
              <b-form-select
                v-model="machineData.simId"
                :options="simList"
                text-field="serial"
                value-field="id"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.machineName') }}</td>
            <td>
              <b-form-input
                v-model="machineData.name"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.machineSerial') }}</td>
            <td>
              <b-form-input
                v-model="machineData.serial"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.gsmSerial') }}</td>
            <td>
              <b-form-input
                v-model="machineData.gsmSerial"
                required
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.coinAcceptor') }}</td>
            <td>
              <b-form-select
                v-model="selectedData.coinAcceptor"
                :options="coinAcceptorList"
                text-field="model"
                value-field="value"
                required
                @input="selectCoinAcc"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.banknoteAcceptor') }}</td>
            <td>
              <b-form-select
                v-model="selectedData.billAcceptor"
                :options="billAcceptorList"
                text-field="model"
                value-field="value"
                required
                @input="selectBillAcc"
              />
            </td>
          </tr>
        </table>
        <div align="right">
          <b-button
            @click="reset"
            variant="danger"
            size="lg"
          >{{ $t('message.back') }}</b-button>&nbsp;
          <b-button
            @click="create"
            variant="primary"
            size="lg"
          >{{ $t('message.create') }}</b-button>
          <br></br>
          <h4
            class='invalidlogin'
            style="color:red; display:none;"
          >{{ $t('message.cannotCreateMachine') }}</h4>
        </div>

      </b-form>
    </b-card>
  </div>
</template>

<script>
import cSwitch from '../../components/Switch'
import webServices from '../../script'
import { buildFormData } from '../../lib/buildFormData'
import { DOMAIN } from '../../app.config'

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
export default {
  name: 'NewMachine',
  components: {
    cSwitch,
    FileUpload
  },
  data() {
    return {
      formData: new FormData(),
      files: [],
      edit: false,
      cropper: false,
      machineData: {},
      machineType: [],
      machineModel: [],
      userList: [],
      coinAcceptorList: [],
      billAcceptorList: [],
      selectedData: {},
      type: '',
      provider: '',
      ProviderList: [
        { id: 'TRUE', name: 'TRUE' },
        { id: 'AIS', name: 'AIS' },
        { id: 'DTAC', name: 'DTAC' },
        { id: 'CAT', name: 'CAT' },
        { id: 'TOT', name: 'TOT' }
      ],
      simList: []
    }
  },
  methods: {
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    async customUpload(file, component) {
      this.formData.append('file', file.file)
    },
    onChange() {
      if (this.$refs.pictureInput.image) {
        var image = this.$refs.pictureInput.file
        const formData = new FormData()
        formData.append('image', image)
      } else {
        this.$toast.error({
          title: 'ERROR',
          message: 'Can not upload file!'
        })
      }
    },
    // ////////////////////////////////////////////////////////
    // get machine details
    getMachineType() {
      webServices
        .getMachineType()
        .then(res => {
          this.machineType = res.data
        })
        .catch(err => {
          console.log('ERROR @MACHINE TYPE')
          console.log(err)
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getMachineModel() {
      this.$Progress.start()
      webServices
        .getMachineModels(this.type)
        .then(res => {
          this.$Progress.finish()
          this.machineModel = res.data
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          console.log('error @model')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getUserList() {
      webServices
        .getUserList('all')
        .then(res => {
          this.userList = res.data
        })
        .catch(err => {
          console.log(err)
          console.log('error @user')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getSimList() {
      webServices
        .getSimList()
        .then(res => {
          this.simList = res.data
        })
        .catch(err => {
          console.log(err)
          console.log('error @simlist')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getSim() {
      webServices
        .getSim(this.provider)
        .then(res => {
          this.simList = res.data
        })
        .catch(err => {
          console.log(err)
          console.log('error @sim')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getCoin() {
      webServices
        .getCoin()
        .then(res => {
          this.coinAcceptorList = res.data
          for (var i = 0; i < this.coinAcceptorList.length; i++) {
            this.coinAcceptorList[i].model = this.coinAcceptorList[i].value.model
          }
        })
        .catch(err => {
          console.log(err)
          console.log('error @coin')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    getBill() {
      webServices
        .getBill()
        .then(res => {
          this.billAcceptorList = res.data
          for (var i = 0; i < this.billAcceptorList.length; i++) {
            this.billAcceptorList[i].model = this.billAcceptorList[i].value.model
          }
        })
        .catch(err => {
          console.log(err)
          console.log('error @bill')
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    selectCoinAcc() {
      this.machineData.coinAcceptor = this.selectedData.coinAcceptor.id
    },
    selectBillAcc() {
      this.machineData.billAcceptor = this.selectedData.billAcceptor.id
    },
    create() {
      this.$Progress.start()
      console.log(this.machineData)

      buildFormData(this.formData, this.machineData)

      webServices
        .createMachine(this.formData)
        .then(res => {
          this.$Progress.finish()
          // console.log(res.data)
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'Machine has been created'
            })
            this.$router.push('/machine/machines')
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          }
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log(err)
          this.$toast.warn({
            title: 'ERROR',
            message: 'Can not create new machine'
          })
        })
    },
    reset() {
      this.$router.push('/machine/machines')
    }
  },
  mounted() {
    this.getMachineType()
    this.getUserList()
    this.getSimList()
    this.getCoin()
    this.getBill()
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.example-avatar .avatar-upload .rounded {
  width: 200px;
  height: 200px;
}
.example-avatar .text-center .btn {
  margin: 0 0.5rem;
}
.example-avatar .avatar-edit-image {
  max-width: 100%;
}
.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>